import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import { HEADER_HEIGHT } from "../../components/header/Header"
import Cards from "../../components/Cards"
import Card from "../../components/Card"
import Orders from "../gifting/transactions/Orders"
import useQueryString from "../../hooks/useQueryString"
import SuppliesCatalogue from "./SuppliesCatalogue"
import { SuppliesOrderContext } from "../../context/SuppliesOrderProvider"
import { Tab, Tabs } from "components"
import { zIndexs } from "model"

const Container = styled.div``
const StyledTabs = styled(Tabs)`
  background-color: white;
  width: 100vw;
  position: fixed !important;
  top: ${HEADER_HEIGHT}px !important;
  z-index: ${zIndexs.giftingPageTabsZIndex};
  height: 50px;
`
const TabContainer = styled.div`
  padding-top: 45px;
`

interface Props {}

const SuppliesPage: FC<Props> = () => {
  const [currentTab, setCurrentTab] = useQueryString("tab", "supplies")
  const context = useContext(SuppliesOrderContext)

  return (
    <Container>
      <StyledTabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
        <Tab title={"Supplies"} id="supplies">
          <TabContainer>
            <SuppliesCatalogue />
          </TabContainer>
        </Tab>
        <Tab title={"Orders"} id="orders">
          <TabContainer>
            <Cards>
              <Card>
                <Orders context={context} />
              </Card>
            </Cards>
          </TabContainer>
        </Tab>
      </StyledTabs>
    </Container>
  )
}

export default SuppliesPage

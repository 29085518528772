import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import PaymentList from "../../orders/PaymentList"
import { OrderContextType, Spinner } from "components"

const Container = styled.div`
  position: relative;
`

interface Props {
  context: OrderContextType
}

const Orders: FC<Props> = ({ context }) => {
  const { orders, isLoadingOrders } = context

  const compare = (a, b) => {
    if (a.date < b.date) {
      return 1
    }
    if (a.date > b.date) {
      return -1
    }
    return 0
  }

  orders.sort(compare)

  return (
    <Container>
      <Spinner isLoading={isLoadingOrders} label={"Loading Orders..."} isLocal />
      <PaymentList payments={orders} />
    </Container>
  )
}

export default Orders
